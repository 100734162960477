.modal {
  min-width: 450px;
  max-height: 85vh;

  input {
    background-color: #f5f4f5;
    border: 2px solid #f5f4f5;
  }

  &.user {
    width: 450px;
    max-width: 500px;
  }

  @media (max-width: 450px) {
    min-width: 375px;
    max-width: 375px;
  }

  @media (max-width: 375px) {
    min-width: 150px;
    max-width: 300px;
  }

  .modal-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .modal-head-close {
      position: relative;
      width: 30px;
      height: 30px;
      background-color: var(--white);
      border: solid 1px var(--tertiary);
      border-radius: 3.75px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.5s ease-in;

      &:hover {
        background-color: darken(white, 15%);
      }
    }
  }
}

.modal-fullscreen {
  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }
}

.mat-dialog-container {
  @media (max-width: 450px) {
   padding: 5px !important;
  }
}



